import React, { useEffect, useRef } from "react";
import { Layout } from "antd";
import AppHeader from "./Header";
import AppFooter from "./Footer";
import AppContent from "./Content";
import AppSidenav from "./SideNav";
import FloatingButton from "../FloatingButton/FloatingButton";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../common";
const { LOG_OUT } = Actions;
let DirectRoutes = [
  "/",
  "/register",
  "/register/profile",
  "/qr",
  "/invoice-pay",
  "/voucher/",
  "/login",
  "/external",
  "/preview",
  "/app-download",
  "/reset-password",
  "/catalogue/user",
  "/offer-listing",
  "/offers",
  "/wallet",
  "/aboutus",
  "/email-unsubscription",
];
const AppLayout = (props) => {
  const { ROLE_FYNDR } = Actions;
  const {
    indv,
    indv: { entityType },
  } = useSelector(({ auth }) => auth);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const findPath = (path) => {
    let index = path.lastIndexOf("/");
    index = index === 0 ? path.length : index;
    return path;
  };
  const dispatch = useDispatch();
  const { isMasquerade } = useSelector(({ admin }) => admin);
  const stateRef = useRef(isMasquerade);

  // useEffect(() => {
  //   console.log("here masquerade..", isMasquerade);
  //   if (isMasquerade === true) {
  //     console.log("here masquerade true", isMasquerade);

  //     window.onbeforeunload = function () {
  //       console.log("here masquerade refreshing..", isMasquerade);

  //       alert("refreshing....");
  //       return true;
  //     };
  //   }
  //   return () => {
  //     console.log(" here masquerade removeing..",isMasquerade)
  //     window.onbeforeunload = null;
  //   };
  // }, []);

  useEffect(() => {
    stateRef.current = isMasquerade;
  }, [isMasquerade]);
  useEffect(() => {
    window.addEventListener("beforeunload", async (e) => {
      if (stateRef.current === true) {
        dispatch({
          type: LOG_OUT,
        });
        // await localStorage.clear();
        var myItem = localStorage.getItem("tourOpen");
        var myItem2 = localStorage.getItem("tourOpenDashboard");
        var navTour = localStorage.getItem("tourSideNav");
        var interactionsTour = localStorage.getItem("tourOpenInteractions");
        var indicatorTour = localStorage.getItem("tourOpenIndicators");

        localStorage.clear();
        localStorage.setItem("tourOpen", myItem);
        localStorage.setItem("tourOpenDashboard", myItem2);
        localStorage.setItem("tourSideNav", navTour);
        localStorage.setItem("tourOpenInteractions", interactionsTour);
        localStorage.setItem("tourOpenIndicators", indicatorTour);

        e.preventDefault();
        // e.returnValue = "";
      }
    });
    return () => {
      window.removeEventListener("beforeunload", (e) => {});
    };
  }, []);

  const offerDetailsPage = props.location.pathname.includes("/offer-details/");
  const myOffersPage = props.location.pathname.includes("/myOffers");
  const isReviewPage = props.location.pathname.includes("/reviews/");
  const isCatalogPage = props.location.pathname.includes("/catalogue/store");
  const EmailUnsubscription = props.location.pathname.includes("/email-unsubscription");

  const is404Page = !DirectRoutes.includes(props.location.pathname);

  return (
    <Layout id="app-layout" className="app-layout fixed-sidenav fixed-header">
      {!["/", "/preview", "/qr", "/offer-listing", "/offers","/myOffers"].includes(
        findPath(props.location.pathname)
      ) && <AppHeader {...props} />}
      <Layout>
        <Layout>
          {entityType &&
            is404Page &&
            !DirectRoutes.includes(findPath(props.location.pathname)) &&
            !isReviewPage &&
            !offerDetailsPage &&
            !myOffersPage&&
            !EmailUnsubscription &&
            !isCatalogPage && <AppSidenav {...props} />}
          <Layout>
            <AppContent />
            {!["/preview", "/offer-listing", "/", "/offers","/myOffers", "/email-unsubscription"].includes(
              findPath(props.location.pathname)
            ) && <AppFooter />}
            {!["/wallet", "/login", "/register", "/email-unsubscription"].includes(
              findPath(props.location.pathname)
            ) &&
              indv.indvid &&
              entityType !== ROLE_FYNDR &&
              !isMasquerade && <FloatingButton />}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default AppLayout;
