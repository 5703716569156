import { combineReducers } from "redux";
import auth from "./authReducer";
import core from "./coreReducer";
import loader from './authReducer';
import cmpn from "./campaignReducer";
import fin from "./finReducer";
import settings from "./settingsReducer";
import webConfig from "./webReducer";
import preview from "./previewReducer";
import catalogue from "./catalogueReducer";
import admin from "./adminReducer";
import isBottom from "./isBottomReducer";

const appReducer = combineReducers({
  auth,
  core,
  cmpn,
  fin,
  webConfig,
  settings,
  preview,
  catalogue,
  admin,
  loader,
  isBottom,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
