import {
  fetch_retry_get,
  fetch_retry_post,
  fetch_retry_put,
} from "../network/ApiManager";

import { Actions } from "../common";
import getEnvVars from "../environment";
import { debug } from "./index";
const {
  CMPNS_FETCHED,
  CMPN_VIEW,
  CMPN_UPSERTED,
  FETCHED_INDV_LIKES,
  FETCHED_BIZ_INFO,
} = Actions;
const { base, cmpn, gatewayNew } = getEnvVars();

export const fetchCampaigns = (bizId) => async (dispatch) => {
  const endpoint = `${cmpn}/campaign/fetch/business/${bizId}?pgStart=0&pgSize=100&status=ALL`;
  try {
    let response = await fetch_retry_get(endpoint, bizId);
    const resp = await response.json();

    if (response.ok) {
      let result = {};
      if (resp.campaigns && resp.campaigns.length > 0) {
        resp.campaigns.forEach((row) => (result[row.objid] = parseCmpn(row)));
      }

      dispatch({ type: CMPNS_FETCHED, payload: result });
      return { success: true };
    } else {
      return { success: false, msg: "Error fetching campaigns" };
    }
  } catch (error) {
    console.warn(error);
  }
};
export const searchBizdir = (params) => async (dispatch) => {
  const { searchStr, page, pgSize, isCategory } = params;
  // if (!searchStr) return { success: false };

  let endpoint = `${base}/catalogue/v2/search?pgStart=${page}&pgSize=${pgSize}`;

  if (searchStr) {
    endpoint = `${endpoint}&text=${searchStr.replace("&", "%26")}`;
  }

  console.log("isCategory:", isCategory);

  delete params.searchStr;
  delete params.page;
  delete params.pgSize;

  debug && console.log("searchBizdir", endpoint, params);
  try {
    const response = await fetch_retry_post(endpoint, params);
    // debug && console.log(response.status);

    if (response.ok) {
      const resp = await response.json();
      let payload = [];
      if (resp.bizdir && resp.bizdir.length > 0) {
        payload = resp.bizdir;
      }
      return { success: true, payload, last: resp.last };
    } else {
      return { success: false, msg: "Error performing search.." };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const searchCmpnsLight = (params) => async (dispatch) => {
  const { searchStr, page, pgSize, orderBy } = params;
  // let endpoint = `${cmpn}/campaign/public/search?pgStart=${page}&pgSize=${pgSize}`;
  let endpoint = `${cmpn}/campaign/v2/public/search?pgStart=${page}&pgSize=${pgSize}`;

  if (searchStr) {
    endpoint = `${endpoint}&text=${searchStr}`;
  }
  if (orderBy) {
    endpoint = `${endpoint}&orderBy=${orderBy}`;
    console.log("orderBy recieved:", orderBy);
  }
  // params.text = searchStr
  params.fetchByGoal = params.fetchByGoal.toUpperCase()
  delete params.searchStr;
  delete params.page;
  delete params.pgSize;
  delete params.orderBy;

  debug && console.log("searchCmpns", endpoint, params);
  try {
    const response = await fetch_retry_post(endpoint, params);
    debug && console.log(response.status);
    if (response.ok) {
      const resp = await response.json();
      return { success: true, payload: resp.campaigns, last: resp.last };
    } else {
      return { success: false, msg: "Error searching campaigns" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const featuredCmpn = (params) => async () => {
  const { searchStr, page, pgSize, orderBy } = params;
  let endpoint = `${cmpn}/campaign/featured/v2/public/search?pgStart=${page}&pgSize=${pgSize}`
  if (searchStr) {
    endpoint = `${endpoint}&text=${searchStr}`;
  }
  // if (orderBy) {
  //   endpoint = `${endpoint}&orderBy=${orderBy}`;
  //   console.log("orderBy recieved:", orderBy);
  // }
  // params.text = searchStr
  params.fetchByGoal = params.fetchByGoal.toUpperCase()
  delete params.searchStr;
  delete params.page;
  delete params.pgSize;

  debug && console.log("searchCmpns", endpoint, params);
  try {
    const response = await fetch_retry_post(endpoint, params);
    debug && console.log(response.status);
    if (response.ok) {
      const resp = await response.json();
      return { success: true, payload: resp.campaigns, last: resp.last };
    } else {
      return { success: false, msg: "Error searching campaigns" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const searchCmpns = (params) => async (dispatch) => {
  const { searchStr, page, pgSize, indvId, orderBy } = params;
  // let endpoint = `${cmpn}/campaign/public/search?pgStart=${page}&pgSize=${pgSize}`;
  let endpoint = `${cmpn}/campaign/v2/public/search?pgStart=${page}&pgSize=${pgSize}`;

  //if logged in
  if (indvId) {
    // endpoint = `${cmpn}/campaign/public/search?pgStart=${page}&pgSize=${pgSize}`;
    endpoint = `${cmpn}/campaign/v2/public/search?pgStart=${page}&pgSize=${pgSize}`;
  }

  if (searchStr) {
    endpoint = `${endpoint}&text=${searchStr}`;
  }

  if (orderBy) {
    endpoint = `${endpoint}&orderBy=${orderBy}`;
    console.log("orderBy recieved:", orderBy);
  }
  // params.text = searchStr
  params.fetchByGoal = params.fetchByGoal.toUpperCase()
  delete params.searchStr;
  delete params.page;
  delete params.pgSize;
  delete params.orderBy;

  try {
    const response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();
    debug && console.log("content", resp);
    if (response.ok && resp.campaigns !== undefined) {
      let payload = [];
      let likesDelta = {};
      if (resp.campaigns && resp.campaigns.length > 0) {
        resp.campaigns.forEach((row) => {
          const parsed = parseCmpn(row);
          payload.push({ [row.objid]: parsed });
          likesDelta[row.objid] = parsed.liked;
        });
      }

      dispatch({ type: FETCHED_INDV_LIKES, payload: likesDelta });
      return { success: true, payload, last: resp.last };
    } else {
      return { success: false, msg: "Error searching campaigns" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const searchCmpnsMaps = (params) => async (dispatch) => {
  const { searchStr,  orderBy } = params;
  // let endpoint = `${cmpn}/campaign/public/search?pgStart=${page}&pgSize=${pgSize}`;
  let endpoint = `${cmpn}/campaign/mapView?`;

  

  if (searchStr) {
    endpoint = `${endpoint}&text=${searchStr}`;
  }

  if (orderBy) {
    endpoint = `${endpoint}&orderBy=${orderBy}`;
    console.log("orderBy recieved:", orderBy);
  }
  // params.text = searchStr
  params.fetchByGoal = params.fetchByGoal.toUpperCase()
  delete params.searchStr;
  delete params.orderBy;

  try {
    const response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();
    if (response.ok && resp !== undefined) {
      let payload = [];
      let likesDelta = {};
      if (resp && resp.length > 0) {
        resp.forEach((row) => {
          const parsed = parseCmpn(row);
          payload.push({ [row.objid]: parsed });
          likesDelta[row.objid] = parsed.liked;
        });
      }
      debug && console.log("content map", payload);

      return { success: true, payload };
    } else {
      return { success: false, msg: "Error searching campaigns" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const searchCmpnsLiked = (params) => async (dispatch) => {
  const { category, searchStr, address, orderBy, page, pgSize, userId } = params;
  // let endpoint = `${cmpn}/campaign/public/search?pgStart=${page}&pgSize=${pgSize}`;
  let endpoint = `${cmpn}/campaign/liked/${userId}?pgStart=${page}&pgSize=${pgSize}`;
  //if logged in
  // if (indvId) {
  //   // endpoint = `${cmpn}/campaign/public/search?pgStart=${page}&pgSize=${pgSize}`;
  //   endpoint = `${cmpn}/campaign/v2/public/search?pgStart=${page}&pgSize=${pgSize}`;
  // }

  if (searchStr) {
    endpoint = `${endpoint}&text=${searchStr}`;
  }

  if (orderBy) {
    endpoint = `${endpoint}&orderBy=${orderBy}`;
    console.log("orderBy recieved:", orderBy);
  }
  // params.text = searchStr
  params.fetchByGoal = params?.fetchByGoal?.toUpperCase()
  delete params?.searchStr;
  delete params?.page;
  delete params?.pgSize;
  delete params?.orderBy;

  try {
    const response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();
    debug && console.log("content", resp);
    if (response.ok && resp.campaigns !== undefined) {
      let payload = [];
      let likesDelta = {};
      if (resp.campaigns && resp.campaigns.length > 0) {
        resp.campaigns.forEach((row) => {
          const parsed = parseCmpn(row);
          payload.push({ [row.objid]: parsed });
          likesDelta[row.objid] = parsed.liked;
        });
      }

      dispatch({ type: FETCHED_INDV_LIKES, payload: likesDelta });
      return { success: true, payload, last: resp.last };
    } else {
      return { success: false, msg: "Error searching campaigns" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const fetchByQR =
  (QR, data, sortBy, sortingOrder, pgSize, pgStart) => async (dispatch) => {
    let endpoint = `${cmpn}/campaign/v2/public/fetchByQR/${QR}`;
    endpoint += `?sortedBy=${sortBy}&orderBy=${sortingOrder}`;
    console.log("campaign/v2/public/fetchByQR", QR, data, sortBy, sortingOrder);
    try {
      const response = await fetch_retry_post(endpoint, data);
      if (response.ok) {
        let resp = await response.json();
        let cmpn = parseCmpn(resp);
        let likesDelta = {};
        likesDelta[cmpn.objid] = cmpn.liked;
        dispatch({ type: FETCHED_INDV_LIKES, payload: likesDelta });
        return { success: true, payload: cmpn };
      } else {
        return { success: false, payload: "failed to fetch the offer" };
      }
    } catch (error) {
      return { success: false, payload: "failed to fetch the offer" };
    }
  };

export const fetchBizinfo = (data) => async (dispatch) => {
  const endpoint = `${cmpn}/campaign/public/indvreviews`;
  debug && console.log("fetchBizinfo", endpoint, data);
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      const { bizCount } = resp;
      const bizDelta = {};
      bizCount.forEach((row) => (bizDelta[row.bizid] = row.count));

      dispatch({ type: FETCHED_BIZ_INFO, payload: bizDelta });
      return { success: true };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error occured while fetching likes",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const offerFeedback = (data) => async (dispatch) => {
  const endpoint = `${cmpn}/campaign/indv_offer`;
  debug && console.log("offerFeedback", endpoint, data);
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    debug && console.log("resp", resp);
    if (response.ok) {
      return { success: true, ...resp };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error saving feedback",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const likeCmpn = (data) => async (dispatch) => {
  const endpoint = `${cmpn}/campaign/indvcmpn`;
  // debug && console.log("likeCmpn", endpoint, data);
  try {
    const response = data.objid
      ? await fetch_retry_put(endpoint, data)
      : await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, payload: resp };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error occured while saving",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const saveCmpn = (data) => async (dispatch) => {
  const endpoint = `${cmpn}/campaign/cmpn`;
  debug && console.log("saveCmpn", endpoint, JSON.stringify(data));

  try {
    // const response = await fetch_retry (endpoint, data, data.objid ? 'put' : 'post');
    const response = data.objid
      ? await fetch_retry_put(endpoint, data)
      : await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      const payload = parseCmpn(resp);
      dispatch({ type: CMPN_UPSERTED, payload });
      return { success: true };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error saving campaign",
    };
  } catch ({ message }) {
    return { success: false, msg: message || "Error saving campaign" };
  }
};

export const getAudienceCount = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/audienceCount`;
  debug && console.log("getAudienceCount", endpoint, data);
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, resp };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error saving feedback",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const getVisitorCount = (data) => async (dispatch) => {
  const endpoint = `${gatewayNew}/interactions/biz/visitorCount`;
  debug && console.log("getVisitorCount", endpoint, data);
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, resp };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error saving feedback",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const fetchPromotions = (cmpn_id) => async (dispatch) => {
  const endpoint = `${cmpn}/campaign/fetch/promotion/${cmpn_id}?pgStart=0&pgSize=300`;
  debug && console.log("getPromotions", endpoint, cmpn_id);
  try {
    const response = await fetch_retry_get(endpoint, {});
    const resp = await response.json();
    debug && console.log(resp);
    if (response.ok) {
      return { success: true, resp };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error saving feedback",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const fetchOngoingPromotions = (params) => async () => {
  const { cmpnId } = params;
  const endpoint = `${cmpn}/campaign/${cmpnId}/promotion/active_featured`;
  delete params.cmpnId;

  try {
    const response = await fetch_retry_get(endpoint, cmpnId);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, ...resp };
    } else {
      return { success: false, msg: "Error getting promotions" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

//internal methods

export const parseCmpn = (payload) => {
  //optimize locations display
  const { cmpnLocs } = payload;

  let cmpnLocOpt = [];
  if (cmpnLocs) {
    let lastDist = 99999;
    let lastAddr = "";

    cmpnLocs.forEach((row) => {
      if (row.distance && Math.abs(lastDist - row.distance) > 1) {
        cmpnLocOpt.push(row);
      } else if (lastAddr !== row.addressLine1) {
        cmpnLocOpt.push(row);
      }
      lastAddr = row.addressLine1;
      lastDist = row.distance;
    });
  }
  return {
    objid: payload.objid,
    cmpnType: payload.cmpnType,
    cmpnUrl: payload.cmpnUrl,
    categoryId: payload.category.categoryId,
    goal: payload.goal,
    startDt: payload.startDt,
    endDt: payload.endDt,
    createdDt: payload.createdDt || new Date(),
    title: payload.title,
    descr: payload.description,
    finePrint: payload.finePrint,
    brochureUri: payload.brochureFile,
    offers: payload.cmpnOffers
      .map((row) => {
        return {
          objid: row.objid,
          title: row.title,
          amount: row.amount,
          couponCode: row.couponCode,
          currency: row.currency,
          currencySymbol: row.currencySymbol,
          discountType: row.discountType,
          imgUri: row.imageFilePath,
          thumbnailUri: row.thumbnailFilePath,
          offerPrice: row.offerPrice,
          offerType: row.offerType,
          retailPrice: row.retailPrice,
          offersAvailable: row.offersAvailable,
          offerLimit: row.offerLimit,
          offerSold: row.offerSold,
          status: row.campaignOfferStatus.toLowerCase(),
          isNew: false,
          isUpdated: false,
          usageLimit: row.usageLimit,
          validityPeriod: row.validityPeriod,
          stdTax: row.stdTax,
          taxPercent: row.taxPercent,
          isBookingEnabled: row.isBookingEnabled,
          displayOrder: row.displayOrder
        };
      })
      .sort((a, b) => a.objid > b.objid),
    cmpnLocs: payload.cmpnLocs.map((row) => row),
    campaignLocationCount: payload.campaignLocationCount,
    cmpnLocIds: payload.cmpnLocs.map((row) => row.locationId),
    dc: payload.deliveryChannel?.split(","),
    deliveryChannel: payload.deliveryChannel,
    status: payload.campaignStatus.toLowerCase(),
    qrCode: payload.qrCode,
    isFeatured: payload.isFeatured,
    likedCount: payload.likedCount,
    featuredStartDt: payload.featuredStartDt,
    featuredEndDt: payload.featuredEndDt,
    paymentSubscription: payload.paymentSubscription,
    biz: payload.biz,
    tags:payload.tags,
    cmpnImgs:
      (payload.images &&
        payload.images
          .map((row) => {
            return {
              imgUri: row.img_url,
              thumbnailUri: row.thumbnail_url,
              index: row.order,
            };
          })
          .sort((a, b) => a.index > b.index)) ||
      [],
    campaignVideo:
      (payload.videos &&
        payload.videos
          .map((row) => {
            return {
              videoUri: row.video_url,
              thumbnailUri: row.thumbnail_url,
              index: row.order,
            };
          })
          .sort((a, b) => a.index > b.index)) ||
      [],
    liked:
      (payload.indvCmpn && {
        likedId: payload.indvCmpn.objid,
        isDeleted: payload.indvCmpn.isDeleted,
      }) ||
      {},
  };
};
export const fetchCmpnForSoloView = (objid) => async (dispatch) => {
  const endpoint = `${cmpn}/campaign/fetch/${objid}`;
  debug && console.log("fetchCmpnForSoloView", endpoint);
  try {
    const response = await fetch_retry_get(endpoint);
    debug && console.log(response.status);
    const resp = await response.json();

    if (response.ok) {
      let parsed = parseCmpn(resp);
      let likesDelta = {};
      likesDelta[objid] = parsed.liked;
      dispatch({ type: FETCHED_INDV_LIKES, payload: likesDelta });
      dispatch({ type: CMPN_VIEW, payload: parsed });
      return { success: true, cmpn: parsed };
    } else {
      return { success: false, msg: "Error fetching campaign" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const fetchReviewsratings =
  (bizid, sortingBy, pgStart, pgSize) => async (dispatch) => {
    const endpoint = `${cmpn}/analytics/business/${bizid}/comments?pgSize=${pgSize}&pgStart=${pgStart}&orderBy=${sortingBy.orderType}&sortedBy=${sortingBy.orderBy}`;
    debug && console.log("fetchCmpnForSoloView", endpoint);
    try {
      const response = await fetch_retry_get(endpoint);
      debug && console.log(response.status);
      const resp = await response.json();

      if (response.ok) {
        return { success: true, cmpn: resp };
      } else {
        return { success: false, msg: "Error fetching ratings" };
      }
    } catch ({ message }) {
      return { success: false, msg: message };
    }
  };

export const addReview = (payload) => async () => {
  const endpoint = `${base}/analytics/business/comment`;
  try {
    let response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};

export const addReply = (params) => async (dispatch) => {
  const { commentId } = params;
  const endpoint = `${base}/analytics/business/${commentId}/reply`;
  debug && console.log("addReply", endpoint, commentId);
  delete params.commentId;
  try {
    const response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();
    debug && console.log("reply resp:", resp);
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return {
        success: false,
        msg: resp.message ? resp.message : "Error saving reply comment",
      };
    }
  } catch (error) {
    return { success: false, msg: error?.response?.data?.errorMessages };
  }
};
export const fetchCommentsById = (params) => async () => {
  const { commentId } = params;
  const endpoint = `${base}/analytics/business/comment/${commentId}`;
  delete params.commentId;
  try {
    const response = await fetch_retry_get(endpoint, commentId);
    const resp = await response.json();
    console.log(resp, "response");
    if (response.ok) {
      return { success: true, data: resp };
    } else {
      return { success: false, msg: "Error fetching comment" };
    }
  } catch ({ errormsg }) {
    return { success: false, msg: errormsg };
  }
};

export const addReport = (params) => async (dispatch) => {
  const { commentId } = params;
  const endpoint = `${base}/analytics/business/${commentId}/report`;
  delete params.commentId;
  try {
    const response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return {
        success: false,
        msg: resp.message ? resp.message : "Error saving report comment",
      };
    }
  } catch (error) {
    return { success: false, msg: error?.response?.data?.errorMessages };
  }
};

export const fetchReviewsOverview = (bizid) => async (dispatch) => {
  const endpoint = `${cmpn}/analytics/business/ratingSummary/${bizid}`;
  debug && console.log("fetchCmpnForSoloView", endpoint);
  try {
    const response = await fetch_retry_get(endpoint);
    debug && console.log(response.status);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, cmpn: resp };
    } else {
      return { success: false, msg: "Error fetching ratings" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const fetchBackgroundImgs = (data) => async (dispatch) => {
  const { lat, lng } = data;
  const endpoint = `${cmpn}/identity/background-image?lat=${lat}&lng=${lng}`;
  debug && console.log("fetchBackgroundImgs", endpoint);
  try {
    const response = await fetch_retry_get(endpoint);
    debug && console.log(response.status);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, payload: resp };
    } else {
      return { success: false, msg: "Error fetching background images" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const fetchcmpnCategoriesList = () => async (dispatch) => {
  const endpoint = `${base}/campaign/public/categories`;
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    const sortedResp = resp.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return sortedResp;
  } catch (error) {
    console.log("error", error);
  }
};
export const fetchCmpnLocation = (params) => async (dispatch) => {
  console.log("data fetchCmpnLocation>>>>", params);
  const endpoint = `${base}/identity/location/business?bizid=${params}`;
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    const sortedResp = resp.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return sortedResp;
  } catch (error) {
    console.log("error", error);
  }
};

export const fetchOfferReport = (params) => async () => {
  const { pgstart, pgSize, businessId, orderBy, sortBy } = params;
  let endpoint = `${base}/campaign/offer_purchase/business/${businessId}?pgStart=${pgstart}&pgSize=${pgSize}`
  if (orderBy !== "") {
    endpoint = `${endpoint}&orderBy=${orderBy}&sortBy=${sortBy}`
  }
  delete params.pgstart;
  delete params.pgSize;
  delete params.businessId;
  delete params.orderBy;
  delete params.sortBy;
  try {
    const response = await fetch_retry_post(endpoint, params);
    console.log("offer report", response)
    const resp = await response.json();
    if (response.ok) {
      return { success: true, ...resp };
    } else {
      return { success: false, msg: "Error fetching offer reports" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
}

export const GiftOffer = (payload) => async () => {

  const endpoint = `${base}/campaign/offer_purchase/gift_offer`

  try {
    const response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, message: resp.data.message };
    } else {
      return { success: false, message: "Error fetching gift reports" };
    }
  } catch (error) {
    return { success: false, message: error.response.data.errorMessages };
  }
}
 export const fetchCancelReason =()=>async()=>{
  const endpoint =`${base}/identity/subscription/cancellation_reason`
  try{
    const response =await fetch_retry_get(endpoint,{});
    const resp =await response.json();
    if(response.ok){
      return {success:true,data:resp}
    }else{
      return {success:false,message:"error fetching resons"}
    }
  }catch(error){
    return { success: false, message: error};

  }
 }

export const campaignActions = {
  fetchCampaigns,
  saveCmpn,
  searchCmpns,
  fetchByQR,
  parseCmpn,
  fetchBizinfo,
  likeCmpn,
  offerFeedback,
  getAudienceCount,
  getVisitorCount,
  fetchPromotions,
  fetchCmpnForSoloView,
  searchCmpnsLight,
  searchBizdir,
  fetchcmpnCategoriesList,
  fetchReviewsratings,
  fetchReviewsOverview,
  fetchCommentsById,
  addReview,
  addReply,
  addReport,
  fetchCmpnLocation,
  fetchOfferReport,
  GiftOffer,
  featuredCmpn,
  fetchBackgroundImgs,
  fetchOngoingPromotions,
  fetchCancelReason,
  searchCmpnsLiked,
  searchCmpnsMaps
};
