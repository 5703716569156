import React, { useEffect, useState } from "react";
import "../../sass/styles/fyndr/_promocodes.scss";
import { Col, List, Row, Typography, Card, message, Button } from "antd";
import promocode2 from "../../assets/images/PromoCode2.png";
import promogif from "../../assets/images/promogif.gif";
import actions from "../../actions";
import { useHistory } from "react-router-dom";

const { Text } = Typography;
const AllPromo = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchstr, setSearchstr] = useState("");
  const [allPromoCode, setAllPromoCode] = useState();
  const [activePromoCodes, setActivePromoCodes] = useState();
  const [selectedPromo, setSelectedPromo] = useState(null);

  const getActivePromos = async () => {
    setLoading(true);
    const pageConsts = { pgStart: 0, pgSize: 100 };
    let resp = await actions.FetchPromoCodes(pageConsts, searchstr);
    if (resp.success === true) {
      setAllPromoCode(resp.resp);
      const promos = resp.resp.promocodesList || [];
      const tempActiveCodes = promos.filter(
        (promo) => promo.promoCodeStatus === "ACTIVE"
      );
      setActivePromoCodes(tempActiveCodes);
    } else {
      console.log("Error fetching data");
    }
  };

  useEffect(() => {
    getActivePromos();
  }, []);
  useEffect(()=>{
    console.log("selected promo", selectedPromo);
  }, [selectedPromo])
  const data = [
    {
      title: "FYNRD35PROMO",
      expiryDate: "24 August 2023",
      cash: "$35",
      users: "256",
    },
    {
      title: "FYNRD35PROMO",
      expiryDate: "24 August 2023",
      cash: "$35",
      users: "256",
    },
    {
      title: "FYNRD35PROMO",
      expiryDate: "24 August 2023",
      cash: "$35",
      users: "256",
    },
  ];
  return (
    <div style={{ padding: "42px 150px 56px " }}>
      <div className="all-promo-container">
        <Row style={{ height: "90vh" }}>
          <Col span={12} style={{ padding: "24px" }}>
            <List
              itemLayout="horizontal"
              className="list-history"
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                style={{cursor:"pointer"}}
                onClick={()=>{
                  setSelectedPromo(item)
                }}
                >
                  <Card
                    className="history-card"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid #D3D6E1",
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <img
                          src={promocode2}
                          alt="promo"
                          style={{
                            width: "105px",
                            height: "105px",
                            borderRadius: "10px",
                          }}
                        />
                      }
                      description={
                        <div
                          style={{
                            height: "105px",
                            padding: "8px 8px 8px 0px",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                          }}
                        >
                          <Row style={{ marginBottom: "5px" }}>
                            <Col span={24}>
                              <Text
                                style={{ color: "#257cdb" }}
                                className="active-promo-heading"
                              >
                                {item.title}
                              </Text>
                            </Col>
                          </Row>
                          <Row style={{ marginBottom: "5px" }}>
                            <Col span={24}>
                              <Text className="active-promo-date">
                                Valid till : {item.expiryDate}
                              </Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Text className="history-content">
                                Sign up using this promo code and get
                              </Text>
                            </Col>
                          </Row>
                        </div>
                      }
                    />
                  </Card>
                </List.Item>
              )}
            />
            <Button
              type="primary"
              onClick={() => {
                history.push("/register", {promoCode : "HOLA"});
              }}
            >
              Signup
            </Button>
          </Col>
          {selectedPromo === null && (
            <Col
              style={{
                background: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              span={12}
            >
              <img src={promogif} style={{ width: "100%" }} />
            </Col>
          )}

          {selectedPromo !== null && (
            <>
              <Col style={{ background: "black" }} span={12}>
              fcgvhbjnk
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default AllPromo;
