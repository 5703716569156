import {
  fetch_retry_delete,
  fetch_retry_get,
  fetch_retry_patch,
  fetch_retry_post,
  fetch_retry_put,
} from "../network/ApiManager";
import getEnvVars from "../environment";
import { Actions } from "../common";
import actions from "./index";

const { MASQUERADE, ADMIN_BACK, DELETE_DATA, STRIPE_DETAILS_UPDATE } = Actions;

const { base, payment } = getEnvVars();
//https://api.dev.fyndr.us /admin/search?pgStart=0&pgSize=10
export const AdminUserSearch = (params) => async (dispatch) => {
  const { pgstart, pgSize, dateOrder } = params;
  let endpoint = `${base}/admin/user/search?pgStart=${pgstart}&pgSize=${pgSize}`;

  console.log("dateOrder", dateOrder);
  if (dateOrder !== "") {
    endpoint = `${endpoint}&dateOrder=${dateOrder}`
  }

  delete params.pgstart;
  delete params.pgSize;
  delete params.dateOrder;
  try {
    let response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error fetching campaigns" };
    }
  } catch (error) {
    console.warn(error);
  }
};
export const AdminUserListDownload = (params) => async (dispatch) => {
  const {dateOrder } = params;

  let endpoint = `${base}/admin/user/search`;
  if (dateOrder !== "") {
    endpoint = `${endpoint}?dateOrder=${dateOrder}`
  }

  delete params.dateOrder;
  try {
    let response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error downloading userlist" };
    }
  } catch (error) {
    console.warn(error);
    return { success: false, msg: error.message };
  }
};
export const AdminUserManagement = (params) => async (dispatch) => {
  const { pgstart, pgSize } = params;
  const endpoint = `${base}/admin/user/fyndr_entity?pgStart=${pgstart}&pgSize=${pgSize}`;

  delete params.pgstart;
  delete params.pgSize;
  try {
    let response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error fetching user details." };
    }
  } catch (error) {
    console.warn(error);
  }
};
export const AdminAddUser = (params) => async (dispatch) => {
  const endpoint = `${base}/admin/user/fyndr_entity/add`;
  const headers = {
    "E-Pass-Base64 ": `Base64 ${btoa(params.pwd)}`
  }
  try {
    let response = await fetch_retry_post(endpoint, params, headers);
    const resp = await response.json();
    console.log("AdminAddUser res", resp, response);

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error on adding user details." };
    }
  } catch (error) {
    return { success: false, msg: error.message || error?.response?.data?.errorMessages[0] };
  }
};
export const AdminUpdateUserPass = (params) => async (dispatch) => {
  const endpoint = `${base}/admin/user/fyndr_entity/updatePassword/${params.objId}`;
  const headers = {
    "E-Pass-Base64 ": `Base64 ${btoa(params.pwd)}`
  }
  try {
    let response = await fetch_retry_patch(endpoint, params, headers);
    const resp = await response.json();
    console.log("Admin update pass res", resp, response);

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error on update password" };
    }
  } catch (error) {
    return { success: false, msg: error.message || error?.response?.data?.errorMessages[0] };
  }
};
export const AdminUpdateUser = (params) => async (dispatch) => {
  const endpoint = `${base}/admin/user/fyndr_entity/update`;
  try {
    let response = await fetch_retry_put(endpoint, params);
    const resp = await response.json();
    console.log("AdminAddUser res", resp, response);

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error on adding user details." };
    }
  } catch (error) {
    return { success: false, msg: error.message || error?.response?.data?.errorMessages[0] };
  }
};
export const AdminUserEntity = (params) => async (dispatch) => {
  const endpoint = `${base}/admin/user/fyndr_entity/roles`;

  try {
    let response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    console.log("AdminUserEntity response>>>", resp);

    if (response.ok) {
      return { success: true, resp };
    } else {
      return { success: false, msg: "Error fetching user details." };
    }
  } catch (error) {
    console.warn(error);
  }
};


export const AdminCampaignSearch = (params) => async (dispatch) => {
  const { pgstart, pgSize, dateOrder } = params;
  let endpoint = `${base}/admin/campaign?pgStart=${pgstart}&pgSize=${pgSize}`;
  if (dateOrder !== "") {
    endpoint = `${endpoint}&dateOrder=${dateOrder}`
  }

  delete params.pgstart;
  delete params.pgSize;
  delete params.dateOrder;
  try {
    let response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error fetching campaigns" };
    }
  } catch (error) {
    console.warn(error);
  }
};
export const UserStatusUpdate = (data, objId) => async (dispatch) => {
  const endpoint = `${base}/admin/user/status/${objId}`;
  try {
    let response = await fetch_retry_put(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: "error updating user status" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const businessNameUpdate = (data, objId) => async (dispatch) => {
  const endpoint = `${base}/admin/user/business/name/${objId}`;
  try {
    let response = await fetch_retry_patch(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, msg: resp?.data?.message };
    } else {
      return { success: false, msg: "error updating business name" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};

export const emailUpdate = (data, objId) => async (dispatch) => {
  const endpoint = `${base}/admin/user/email/${objId}`;
  try {
    let response = await fetch_retry_patch(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, msg: resp?.data?.message };
    } else {
      return { success: false, msg: "error updating business name" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};

export const verifyStripeRegistrationAdmin = (id) => async (dispatch) => {
  const endpoint = `${payment}/secure/verifyStripeRegistration?&indvid=${id}`;
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (resp.success === true) {
      if (resp.info) {
      }
      return { success: true, response: resp };
    } else {
      return { success: false, message: "Error connecting to stripe" };
    }
  } catch (error) {
    if (error?.response?.data?.info) {
      dispatch({
        type: STRIPE_DETAILS_UPDATE,
        payload: error?.response?.data?.info,
      });
    }
    return { success: false, message: error?.response?.data?.message };
  }
};
export const ResetStripeStatus = (userId) => async () => {
  const endpoint = `${base}/admin/user/${userId}/stripe/reset-notification`;
  try {
    const response = await fetch_retry_post(endpoint, userId);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, resp }
    } else {
      return { success: false, msg: "Error sending request" }
    }
  } catch (error) {
    return { success: false, msg: error?.response?.data?.message };

  }

}

export const AdminDashboardStatistics = (api) => async (dispatch) => {
  console.log("api", api);
  const endpoint = `${base}${api}`;
  try {
    let response = api.includes("admin/statistics/user") ? await fetch_retry_post(endpoint) : await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, data: Object.entries(resp.data) };
    } else {
      return { success: false, msg: "can't fetch data" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const MasqueradeUser = (id) => async (dispatch) => {
  const endpoint = `${base}/admin/user/masquerade/${id}`;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = await response.json();

    if (response.ok) {
      await localStorage.setItem("accessToken", resp.accessCode);
      if (resp?.user.userEntity !== "FYNDR") {
        let getAccResponse = await actions.getAccount({
          email: resp.user.email,
          regMode: "facebook",
        });
        if (getAccResponse.status === "exists") {
          dispatch({
            type: MASQUERADE,
            payload: true,
          });
          return { success: true, role: getAccResponse.entityType };
        } else {
          //reset the entire admin panel
          // let getAccResponse = await actions.getAccount({email:resp.user.email,regMode:"facebook"})
          return {
            success: false,
            msg: "Something went wrong",
          };
        }
      } else {
        return {
          success: false,
          msg: "Can't be logged-in as an admin account",
        };
      }
    } else {
      return { success: false, msg: "Error fetching campaigns" };
    }
  } catch (error) {
    console.warn(error);
    return { success: false, msg: error.message };
  }
};

export const detailsApi = (params) => async (dispatch) => {
  const { pgstart, pgSize } = params;
  const endpoint = `${base}/admin/revenue?pgStart=${pgstart}&pgSize=${pgSize}`;

  delete params.pgstart;
  delete params.pgSize;
  try {
    let response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error fetching revenue details" };
    }
  } catch (error) {
    console.warn(error);
  }
};
export const disputeListings = (params) => async (dispatch) => {
  const { pgstart, pgSize } = params;
  const endpoint = `${base}/admin/dispute/search/?pgStart=${pgstart}&pgSize=${pgSize}`;

  delete params.pgstart;
  delete params.pgSize;
  try {
    let response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error fetching details" };
    }
  } catch (error) {
    console.warn(error);
  }
};
export const PlatformVariablesListings = (params) => async (dispatch) => {
  const { pgstart, pgSize } = params;
  const endpoint = `${base}/admin/settings/list?pgStart=${pgstart}&pgSize=${pgSize}`;

  delete params.pgstart;
  delete params.pgSize;
  try {
    let response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, ...resp.data };
    } else {
      return { success: false, msg: "Error fetching platform variables" };
    }
  } catch (error) {
    console.warn(error);
  }
};
export const RefundApi = (data) => async () => {
  const endpoint = `${payment}/secure/refund`;
  try {
    let response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: "" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const DisputeStatusUpdate = (payload, disputeId) => async () => {
  const endpoint = `${base}/admin/dispute/status/${disputeId}`;
  try {
    let response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.data.message };
    } else {
      return { success: false, msg: "error updating dispute status" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const SaveComments = (payload) => async () => {
  const endpoint = `${base}/admin/dispute/comment`;
  try {
    let response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.data.message };
    } else {
      return { success: false, msg: "" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const AddPlatformVariables = (payload) => async () => {
  const endpoint = `${base}/admin/settings/create`;
  try {
    let response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.data.message };
    } else {
      return { success: false, msg: resp.data.message };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};

const UpdatePlatformVariables =
  (payload, platformVariableId) => async (dispatch) => {
    const endpoint = `${base}/admin/settings/platformVariable/${platformVariableId}`;
    try {
      let response = await fetch_retry_put(endpoint, payload);
      const resp = await response.json();
      if (response.ok) {
        return { success: true, msg: resp.message };
      } else {
        return { success: false, msg: "error updating Platform variables" };
      }
    } catch (error) {
      return { success: false, msg: error.message };
    }
  };

const UpdateReportedComment = (params) => async () => {
  const { commentId } = params;
  const endpoint = `${base}/admin/comment/${commentId}/ignore`;
  delete params.commentId;
  try {
    let response = await fetch_retry_put(endpoint, params);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp?.data?.message };
    } else {
      return { success: false, msg: "error ignoring reported comments " };
    }
  } catch (error) {
    return { success: false, msg: error?.response?.data?.message };
  }
};

const DeleteComment = (params) => async () => {
  const { commentId } = params;
  const endpoint = `${base}/admin/comment/${commentId}`;
  delete params.commentId;
  try {
    let response = await fetch_retry_delete(endpoint, params);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp?.data?.message };
    } else {
      return { success: false, msg: "error deleting reported comments" };
    }
  } catch (error) {
    return { success: false, msg: error?.response?.data?.message };
  }
};

const DeleteUser = (userId) => async () => {
  const endpoint = `${base}/admin/user/fyndr_entity/${userId}`;
  try {
    let response = await fetch_retry_delete(endpoint, userId);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp?.data?.message };
    } else {
      return { success: false, msg: "error deleting the user!" };
    }
  } catch (error) {
    return { success: false, msg: error?.response?.data?.message };
  }
};

const fetchCommentsForAdmin = (disputeId) => async () => {
  const endpoint = `${base}/admin/dispute/fetchComments/${disputeId}`;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = response.json();
    return { success: true, data: resp.data };
  } catch (error) {
    console.log(error);
  }
};

export const Reportlistings = (params) => async () => {
  const { pgstart, pgSize, orderBy } = params;
  const endpoint = `${base}/admin/comment/list?orderBy=${orderBy}&pgStart=${pgstart}&pgSize=${pgSize}`;
  delete params.pgstart;
  delete params.pgSize;
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, ...resp };
    } else {
      return { success: false, msg: "Error fetching ratings" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const fetchCommentsByIdForAdmin = (params) => async () => {
  const { commentId } = params;
  const endpoint = `${base}/admin/comment/${commentId}`;
  delete params.commentId;
  try {
    const response = await fetch_retry_get(endpoint, commentId);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, data: resp };
    } else {
      return { success: false, msg: "Error fetching comment" };
    }
  } catch ({ errormsg }) {
    return { success: false, msg: errormsg };
  }
};

export const ListReasons = (params) => async (dispatch) => {
  const { action } = params;
  const endpoint = `${base}/admin/comment/report/listReasons?action=${action}`;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, data: resp };
    }
  } catch (error) {
    return { success: false, msg: "" };
  }
};

export const CampnStatusUpdate = (data, campaignId) => async (dispatch) => {
  const endpoint = `${base}/admin/campaign/status/${campaignId}`;
  try {
    let response = await fetch_retry_put(endpoint, data);
    const resp = await response.json();
    console.log("resp", resp);

    if (response.ok) {
      return { success: true, msg: resp.data.message };
    } else {
      return { success: false, msg: "error updating campaign status" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};

export const GetCountryList = () => async (dispatch) => {
  const endpoint = `${base}/admin/country/list`;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = response.json();
    return resp;
  } catch (error) {
    console.log(error);
  }
};

export const adminSetting = (payload) => async (dispatch) => {
  const endpoint = `${base}/admin/settings/fetch`;
  try {
    let response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      console.log("end" + resp);

      return { success: true, data: resp };
    } else {
      console.log("catch else");
      return { success: false, msg: "error" };
    }
  } catch (error) {
    console.log(" catch" + JSON.stringify(error?.message));
    return { success: false, error: JSON.stringify(error?.message) };
  }
};

export const AdminFeatureCampaign = (data) => async (dispatch) => {
  const endpoint = `${base}/admin/campaign/promotion`;
  try {
    let response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: "Error promoting campaign" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};

export const FetchPromoCodes = (params) => async (dispatch) => {
  const { text, status } = params
  let endpoint = `${base}/admin/promocode/list?`
  if (text) {
    endpoint = `${endpoint}text=${text}`;
  }
  if (status) {
    endpoint = `${endpoint}&status=${status}`;
  }
  delete params.status;
  delete params.text;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, resp };
    } else {
      return { success: false, msg: "Error fetching promocodes" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
}

const FetchExpiredPromoCodes = (data) => async () => {
  const { text, pgStart, pgSize } = data;
  const endpoint = `${base}/admin/promocode/expired?text=${text}&pgStart=${pgStart}&pgSize=${pgSize}`;
  delete data.text;
  delete data.pgSize;
  delete data.pgStart;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, resp };
    } else {
      return { success: false, msg: "Error fetching promocodes" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }

}

export const addPromo = (payload) => async () => {
  const endpoint = `${base}/admin/promocode/add`;
  try {
    const response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, resp };
    } else {
      return { success: false, msg: "Error adding promocode" }
    }
  } catch (error) {
    return { success: false, msg: error?.response?.data?.message }
  }
}
export const getPromoById = (promocode) => async () => {
  const endpoint = `${base}/admin/promocode/fetch/${promocode}`;
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, data: resp };
    } else {
      return { success: false, msg: "Error fetching promo code details" }
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
}
const updatePromoCode = (id, payload) => async () => {
  const endpoint = `${base}/admin/promocode/update/${id}`;
  try {
    const response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message }
    }
    else {
      return { success: false, msg: "Error updating promocode" }
    }
  } catch (error) {
    return { success: false, msg: error?.message };
  }
}
export const BackToAdmin =
  ({ email, regMode }) =>
    async (dispatch) => {

      localStorage.setItem(
        "accessToken",
        localStorage.getItem("adminAccessToken")
      );

      let response = await actions.getAccount({
        email: email,
        regMode: regMode === "google" ? regMode : "facebook",
      });
      if (response.status === "exists") {
        dispatch({
          type: ADMIN_BACK,
        });
        dispatch({
          type: DELETE_DATA,
        });
        return { success: true, role: response.entityType };
      }
    };
// export const fetchCmpnType = (params) => async () => {
//   const { pgstart, pgSize} = params;
// const endpoint = `${base}/admin/campaign?pgStart=${pgstart}&pgSize=${pgSize}`
//   delete params.pgstart;
//   delete params.pgSize;
//   try {
//     const response = await fetch_retry_post(endpoint,params);
//     console.log("cmpAdmin",response)
//     const resp = await response.json();
//     if (response.ok) {
//       return { success: true, ...resp };
//     } else {
//       return { success: false, msg: "Error fetching Campaign types" };
//     }
//   } catch ({ message }) {
//     return { success: false, msg: message };
//   }
// }

export const adminActions = {
  AdminUserSearch,
  AdminUserManagement,
  AdminCampaignSearch,
  UserStatusUpdate,
  MasqueradeUser,
  BackToAdmin,
  AdminDashboardStatistics,
  detailsApi,
  disputeListings,
  RefundApi,
  DisputeStatusUpdate,
  SaveComments,
  fetchCommentsForAdmin,
  CampnStatusUpdate,
  PlatformVariablesListings,
  GetCountryList,
  AddPlatformVariables,
  UpdatePlatformVariables,
  Reportlistings,
  UpdateReportedComment,
  fetchCommentsByIdForAdmin,
  ListReasons,
  DeleteComment,
  DeleteUser,
  adminSetting,
  businessNameUpdate,
  AdminUserEntity,
  AdminAddUser,
  AdminUpdateUser,
  AdminUpdateUserPass,
  verifyStripeRegistrationAdmin,
  emailUpdate,
  ResetStripeStatus,
  AdminFeatureCampaign,
  addPromo,
  getPromoById,
  updatePromoCode,
  AdminUserListDownload,
  FetchPromoCodes,
  FetchExpiredPromoCodes,
};
