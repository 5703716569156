import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Radio,
  message,
  Button,
  Typography,
  Card,
  Switch,
  Modal,
  Checkbox,
} from "antd";
import Loader from "../../components/Loading/Loader";
import UserActions from "../../actions";
import { Theme } from "../../common";
import AddCard from "./AddCard";
import { PlusOutlined } from "@ant-design/icons";
import AllAppointment from "../../components/AllAppointment/AllAppointment";
import GoogleLogin, { useGoogleLogin } from "react-google-login";
import getEnvVars from "../../environment";
import { gapi } from "gapi-script";
import ReceiverForm from "../../components/Gift/ReceiverForm";
import backbtn from "../../assets/images/blackBackArrow.svg";
import "../../sass/styles/fyndr/_gift.scss";
import FormDetailsPage from "../../components/Gift/FormDetailsPage";

const { Text } = Typography;

const CardPay = (props) => {
  const {
    total,
    params: { tip, channel, biz },
    callback,
    tax,
    amount,
    isSubscriptionEnabled
  } = props;
  let invoiceId = props.params.invoiceId;
  const {
    app: { facbookAppId, googleClientId },
  } = getEnvVars();
  const CLIENT_ID =
    "283240579326-2jp6j8jvekme9o4ddcsesa8rl81evidf.apps.googleusercontent.com";

  const { qrcode, cards, qrid, indvid } = useSelector(({ auth }) => auth.indv);
  const {
    payables,
    cartInfo: {
      bizid,
      merchantId,
      baseAmount,
      currency,
      currencySymbol,
      taxAmount,
      invoiceDetails,
    },
  } = useSelector(({ fin }) => {
    return fin;
  });

  let resultAppointment =
    channel === "catalog_appointment" || channel === "catalog"
      ? invoiceDetails?.items?.map(
          (item) => item?.details?.mitem?.appointment?.length > 0
        )
      : invoiceDetails?.offers?.map((item) => item?.appointment?.length > 0);

  const [newCard, setNewCard] = useState(
    !cards || cards.length === 0 ? true : false
  );
  const [selectedCard, setSelectedCard] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [walletCash, setWalletCash] = useState([]);
  const [fyndrCash, setFyndrCash] = useState(false);
  const [consentVisible, setConsentVisible] = useState(false);
  const [isBuyerGooglePermissionGranted, setIsBuyerGooglePermissionGranted] =
    useState(false);
  const [declined, setDeclined] = useState(false);
  const [isGiftChecked, setIsGiftChecked] = useState(false);
  const [formData, setFormData] = useState(null);
  const [showReceiverForm, setShowReceiverForm] = useState(false);
  const [additionalCheckbox, setAdditionalCheckbox] = useState(false);

  const message = formData?.message;

  // const [htmlContent, setHtmlContent] = useState(null);

  // useEffect(() => {
  //   const fetchHtmlContent = async () => {
  //     try {
  //       const response = await fetch('https://accounts.google.com/o/oauth2/auth?client_id=1068896131755-vckn3h3sgl50i1or7ohn2m2hpfqco5dg.apps.googleusercontent.com&redirect_uri=http://localhost:9093/appointment/googleCalendar/permission&response_type=code&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile');

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch HTML content');
  //       }

  //       const blob = await response.blob();
  //       const html = URL.createObjectURL(blob);
  //       setHtmlContent(html);
  //     } catch (error) {
  //       console.error('Error fetching HTML content:', error);
  //     }
  //   };

  //   fetchHtmlContent();

  //   // Cleanup function to revoke the object URL
  //   return () => {
  //     if (htmlContent) {
  //       URL.revokeObjectURL(htmlContent);
  //     }
  //   };
  // }, []); // Only run once on component mount

  useEffect(() => {
    // gapi?.load("client:auth2", () => {
    //   gapi?.auth2?.init({ clientId: CLIENT_ID })
    // })

    if (gapi?.auth2 && gapi?.auth2?.getAuthInstance()) {
      // Use the existing instance
      var authInstance = gapi?.auth2?.getAuthInstance();
      // Now you can use authInstance for authentication
    } else {
      // Initialize gapi.auth2 with your desired options
      gapi?.auth2
        ?.init({
          client_id: CLIENT_ID,
        })
        .then(function (authInstance) {
          // Now you can use authInstance for authentication
        })
        .catch(function (error) {
          console.error("Error initializing Google Auth: ", error);
        });
    }
  }, []);

  useEffect(() => {
    if (!additionalCheckbox) {
      setIsGiftChecked(false);
      setFormData(null);
    }
  }, [additionalCheckbox]);

  useEffect(() => {
    if (cards && cards.length > 0)
      setSelectedCard(cards.filter((row) => row.default === "y")[0]["id"]);
  }, [cards]);

  useEffect(() => {
    setNewCard(false);
  }, [total]);

  useEffect(async () => {
    setIsBuyerGooglePermissionGranted(isBuyerGooglePermissionGranted);
    let token = await localStorage.getItem("googleAccessToken");
    setIsBuyerGooglePermissionGranted(
      token === null || token === "noToken" ? false : true
    );
  }, []);

  const verifyWallet = async () => {
    const payload = {
      channel: channel.toUpperCase(),
      totalAmount: total,
    };
    let resp = await UserActions.verifyWalletForTransac(payload, indvid);
    if (resp?.success) {
      setWalletCash(resp.data);
    }
  };
  const { signIn } = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("useGoogleLogin>>>", codeResponse);
      localStorage.setItem("googleAccessToken", codeResponse?.accessToken);
      getGooglePermission();
    },
    onFailure: (error) => console.log("Login Failed:", error),
    clientId: CLIENT_ID,
    redirectUri:
      "https://api.dev.fyndr.us/appointment/googleCalendar/permission",
    scope:
      "profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    accessType: "offline",
    responseType: "token",
    prompt: "consent",
    cookiePolicy: "single_host_origin",
  });

  const googleSignInSucceded = async (codeResponse) => {
    console.log("useGoogleLogin>>>", codeResponse);
    localStorage.setItem("googleAccessToken", codeResponse?.accessToken);
    getGooglePermission();
  };

  const googleSignInFailed = async (error) => {
    setIsLoading(false);
    console.log("Login Failed:", error);
  };

  const getGooglePermission = async () => {
    let result = await UserActions.GooglePermission();
    // let result = await UserActions.googlePermissionCheck();

    if (result.success) {
      setIsLoading(false);
      handlePay(true);
    } else {
      setIsLoading(false);
      message.error(result?.msg);
    }
  };

  const handlePay = async () => {
    if (!selectedCard) return false;

    let createdInvoice;
    let paymentMethod;
    await fetchIpAddress();
    //save tip to db if needed (interaction invoices)
    if (tip !== null && tip > 0 && invoiceId) {
      let invoice = payables[invoiceId];
      invoice.tipAmount = tip;
      invoice.buyerId = indvid;
      invoice.bizid = biz.bizid;
      invoice.updateType = "payment";
      setIsLoading(true);
      const resp = await UserActions.saveInvoice(invoice, "pmt");
      setIsLoading(false);

      if (!resp.success) {
        message.error(resp?.msg);
        return false;
      }
    }

    let ok = true;
    if (
      channel === "offers" ||
      channel === "offer_appointment" ||
      channel === "events" ||
      channel === "cmpn_promo" ||
      channel === "catalog" ||
      (channel === "catalog_appointment" && invoiceId == null)
    ) {
      //create invoice first
      setIsLoading(true);
      const { success, invoice, msg } = await UserActions.saveInvoice(
        channel !== "catalog" && channel !== "catalog_appointment"
          ? {
            buyerQRId: qrid,
            bizid,
            merchantId,
            baseAmount,
            taxAmount: isNaN(taxAmount) ? 0 : taxAmount,
            currency,
            currencySymbol,
            invoiceDetails,
            channel,
            tipAmount: tip,
            fyndrCash: fyndrCash ? walletCash.fyndrCash : 0,
            isBuyerGooglePermissionGranted: isBuyerGooglePermissionGranted,
          }
          : {
            buyerQRId: qrid,
            bizid,
            merchantId,
            baseAmount,
            taxAmount: isNaN(taxAmount) ? 0 : taxAmount,
            currency,
            currencySymbol,
            invoiceDetails,
            channel,
            tipAmount: tip,
            isBuyerGooglePermissionGranted: isBuyerGooglePermissionGranted,
          },
        "pay"
      );
      setIsLoading(false);

      if (!success) {
        message?.error(msg);
        ok = false;
      } else {
        invoiceId = invoice.objid;
        createdInvoice = invoice;
      }
    }

    if (!ok) return false;

    setIsLoading(true);
    const payload = {};
    if (additionalCheckbox && formData?.phoneNumber !=="" && formData?.phoneNumber !==null && formData?.phoneNumber !== undefined) {
      payload.giftOfferDetails = {
        gifteePhone: formData?.phoneNumber,
        countryCode: formData?.phoneNumber && formData?.countryCode
      };
    }

    try {
      const resp = await UserActions.stripePay({
        qrcode,
        invoiceId,
        cardId: selectedCard,
        lat: lat ? lat : null,
        lng: lng ? lng : null,
        isOfferGifted: additionalCheckbox,
        isSubscriptionEnabled:isSubscriptionEnabled,
        ...payload,
      });
      setIsLoading(false);

      if (resp?.success) {
        paymentMethod = resp?.data?.pay;
        callback(formData, invoiceId, additionalCheckbox, createdInvoice, paymentMethod);
      } else {
        message.error(resp?.message);
      }
    } catch (error) {
      message.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    verifyWallet();
  }, [total]);
  const onChange = (value) => {
    setSelectedCard(value.target.value);
  };

  const fetchIpAddress = async () => {
    if (navigator.geolocation) {
      try {
        await navigator.geolocation.getCurrentPosition(setLatLng);
      } catch (error) {}
    } else {
      alert("geolocation is not supported");
    }
  };

  function setLatLng(position) {
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);
  }

  const handleCheckboxChange = (e) => {
    setIsGiftChecked(!isGiftChecked);
    setAdditionalCheckbox(!additionalCheckbox);
  };

  const handleEditClick = () => {
    setShowReceiverForm(true);
  };

  return (
    <>
      {isGiftChecked || showReceiverForm ? (
        <ReceiverForm
          initialData={formData}
          onClose={() => setShowReceiverForm(false)}
          onClick={handleCheckboxChange}
          callback={(formDataWithCountryCode) => {
            setIsGiftChecked(false);
            setAdditionalCheckbox(true);
            setFormData(formDataWithCountryCode);
            setShowReceiverForm(false);
          }}
          mode={showReceiverForm ? "update" : "create"}
        />
      ) : (
        <Row style={props.extraStyle} justify="center">
          <Col xs={24} md={24}>
            <Card
              className="card-receiver"
              title={
                formData ? (
                  <span>
                    <img
                      src={backbtn}
                      onClick={handleEditClick}
                      style={{ cursor: "pointer" }}
                    />{" "}
                    <span>Payment</span>
                  </span>
                ) : (
                  "Select Card"
                )
              }
            >
              <Row justify="center">
                {!formData ? (
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <Radio.Group
                      style={{ minWidth: "100%" }}
                      onChange={onChange}
                      value={selectedCard}
                    >
                      {cards &&
                        !newCard &&
                        cards.length > 0 &&
                        cards.map((card, index) => {
                          return (
                            <Row
                              key={index}
                              style={{
                                background: "#F2F6FF",
                                padding: "0.3rem",
                                paddingTop: "0.5rem",
                                borderRadius: "5px",
                                margin: "0.5rem 0rem",
                              }}
                              align="middle"
                              justify="space-between"
                            >
                              <Col>
                                <i
                                  style={{
                                    color: Theme.blue,
                                    fontSize: "28px",
                                  }}
                                  className={`fa fa-cc-${card.brand}`}
                                  aria-hidden="true"
                                ></i>
                              </Col>
                              <Col align="middle">
                                <h6
                                  style={{
                                    color:
                                      card.default === "y"
                                        ? Theme.green
                                        : "#000",
                                  }}
                                >
                                  {`**** **** **** ${card.last4}`}
                                </h6>
                              </Col>
                              <Col align="middle">
                                <Radio value={card.id}></Radio>
                              </Col>
                            </Row>
                          );
                        })}
                    </Radio.Group>
                    {!newCard && (
                      <Row justify="center" gutter="10">
                        <Button
                          size="large"
                          type="text"
                          onClick={() => setNewCard(true)}
                          className="add-new-btn"
                        >
                          <Text style={{ color: "#223369" }} strong>
                            Add a new card
                          </Text>
                          <PlusOutlined
                            style={{
                              fontSize: 15,
                              color: "#223369",
                              verticalAlign: "middle",
                              border: "2px solid #223369",
                              lineHeight: 0,
                              padding: 2,
                              borderRadius: "5px",
                            }}
                          />
                        </Button>
                      </Row>
                    )}
                    {/* <button onClick={handleGoogleAuth}>Login with Google</button> */}

                    {newCard && (
                      <Row justify="center">
                        <AddCard callback={() => setNewCard(false)} />
                      </Row>
                    )}
                  </Col>
                ) : (
                  <>
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <Radio.Group
                        style={{ minWidth: "100%" }}
                        onChange={onChange}
                        value={selectedCard}
                      >
                        {cards &&
                          !newCard &&
                          cards.length > 0 &&
                          cards.map((card, index) => {
                            return (
                              <Row
                                key={index}
                                style={{
                                  background: "#F2F6FF",
                                  padding: "0.3rem",
                                  paddingTop: "0.5rem",
                                  borderRadius: "5px",
                                  margin: "0.5rem 0rem",
                                }}
                                align="middle"
                                justify="space-between"
                              >
                                <Col>
                                  <i
                                    style={{
                                      color: Theme.blue,
                                      fontSize: "28px",
                                    }}
                                    className={`fa fa-cc-${card.brand}`}
                                    aria-hidden="true"
                                  ></i>
                                </Col>
                                <Col align="middle">
                                  <h6
                                    style={{
                                      color:
                                        card.default === "y"
                                          ? Theme.green
                                          : "#000",
                                    }}
                                  >
                                    {`**** **** **** ${card.last4}`}
                                  </h6>
                                </Col>
                                <Col align="middle">
                                  <Radio value={card.id}></Radio>
                                </Col>
                              </Row>
                            );
                          })}
                      </Radio.Group>
                      {!newCard && (
                        <Row justify="center" gutter="10">
                          <Button
                            size="large"
                            type="text"
                            onClick={() => setNewCard(true)}
                            className="add-new-btn"
                          >
                            <Text style={{ color: "#223369" }} strong>
                              Add a new card
                            </Text>
                            <PlusOutlined
                              style={{
                                fontSize: 15,
                                color: "#223369",
                                verticalAlign: "middle",
                                border: "2px solid #223369",
                                lineHeight: 0,
                                padding: 2,
                                borderRadius: "5px",
                              }}
                            />
                          </Button>
                        </Row>
                      )}
                      {/* <button onClick={handleGoogleAuth}>Login with Google</button> */}

                      {newCard && (
                        <Row justify="center">
                          <AddCard callback={() => setNewCard(false)} />
                        </Row>
                      )}

                      {!newCard && cards?.length > 0 && (
                        <Row justify="center">
                          <Button
                            style={{
                              background: "#223369",
                              borderRadius: "5px",
                              padding: "0 2.5rem",
                              width: "100%",
                              margin: "0.2rem 0.6rem 0.6rem",
                            }}
                            disabled={isLoading}
                            type="primary"
                            size="large"
                            onClick={() => {
                              handlePay();
                            }}
                          >
                            Pay Now ${fyndrCash ? (total - walletCash?.fyndrCash).toFixed(2) : total}{isLoading && <Loader />}
                          </Button>
                        </Row>
                      )}
                    </Col>
                  </>
                )}
                <Col
                  xs={24}
                  sm={24}
                  md={10}
                  xl={10}
                  className="amount-slip"
                  style={{ padding: formData ? "3%" : "5%" }}
                >
                  <Row>
                    <Col span={12}>
                      <h6>Amount</h6>
                    </Col>
                    <Col span={1}>
                      <h6>:</h6>
                    </Col>
                    <Col span={11} style={{ textAlign: "center" }}>
                      <h6>${amount ? amount : baseAmount}</h6>
                    </Col>

                    <Col span={12}>
                      <h6>Tax</h6>
                    </Col>
                    <Col span={1}>
                      <h6>:</h6>
                    </Col>
                    <Col span={11} style={{ textAlign: "center" }}>
                      <h6>${tax ? tax : taxAmount}</h6>
                    </Col>
                    {tip >0 &&
                    <>
                    <Col span={12}>
                      <h6>Tip</h6>
                    </Col>
                    <Col span={1}>
                      <h6>:</h6>
                    </Col>
                    <Col span={11} style={{ textAlign: "center" }}>
                      <h6>${Number(tip).toFixed(2).toString()}</h6>
                    </Col>
                    </>
                    }
                    {fyndrCash &&
                      <>
                        <Col span={12}>
                          <h6>Fyndr Cash</h6>
                        </Col>
                        <Col span={1}>
                          <h6>:</h6>
                        </Col>
                        <Col span={11} style={{ textAlign: "center" }}>
                          <h6>-${walletCash?.fyndrCash}</h6>
                        </Col>
                      </>
                    }

                    <Col span={12}>
                      <h6>Total Amount</h6>
                    </Col>
                    <Col span={1}>
                      <h6>:</h6>
                    </Col>
                    <Col span={11} style={{ textAlign: "center" }}>
                      {fyndrCash ? (
                        <h6>${(total - walletCash?.fyndrCash).toFixed(2)}</h6>
                      ) : (
                        <h6>${total}</h6>
                      )}
                    </Col>

                    {!formData ? (
                      <>
                        {channel !== "catalog" &&
                          walletCash?.isWalletActive === true && (
                            <Col span={24}>
                              <Row
                                style={{
                                  background: "#F2F6FF",
                                  borderRadius: "5px",
                                  marginTop: "2rem",
                                  padding: "0.4rem 0.09rem 0.2rem 0",
                                  borderColor: Theme.green,
                                  border: "0.2rem solid #2DCE89",
                                }}
                                align="middle"
                                justify="space-around"
                              >
                                <Col>
                                  <h6
                                    style={{
                                      color: "#223369",
                                      textAlign: "center",
                                      marginTop: "0.4rem",
                                      marginRight: "0.1rem",
                                      marginLeft: "0.1rem",
                                    }}
                                  >
                                    {`Fyndr Cash $${walletCash?.fyndrCash}`}
                                  </h6>
                                </Col>

                                <Col>
                                  <Switch
                                    title="Use wallet amount $40"
                                    defaultChecked={fyndrCash}
                                    onChange={(e) => setFyndrCash(e)}
                                    style={{
                                      marginRight: "0.1rem",
                                      marginLeft: "0.1rem",
                                    }}
                                    size="small"
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          )}
                        {(channel === "offers" ||
                          channel === "offer_appointment") && (
                          <Col
                            span={24}
                            style={{
                                display: "flex",
                              justifyContent: "left",
                                marginTop: "1rem",
                              }}
                            >
                              <Checkbox
                                onChange={handleCheckboxChange}
                                checked={isGiftChecked}
                              />

                              <h6
                                style={{
                                  marginLeft: 8,
                                  color: "#223369",
                                  marginTop: "3.7px",
                                }}
                              >
                                Make this as a Gift
                              </h6>
                            </Col>
                          )}
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                          }}
                        >
                          {!newCard && cards?.length > 0 && (
                            <Row justify="center">
                              <Button
                                disabled={isLoading}
                                type="primary"
                                size="large"
                                onClick={() => {
                                  handlePay();
                                }}
                                className="offer_cards--active"
                              >
                                Pay Now ${fyndrCash ? (total - walletCash?.fyndrCash).toFixed(2) : total}{isLoading && <Loader />}
                              </Button>
                            </Row>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col
                            span={24}
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              marginTop: "1rem",
                            }}
                          >
                            <Checkbox
                              onChange={handleCheckboxChange}
                              checked={additionalCheckbox}
                            />

                            <h6
                              style={{
                                marginLeft: 8,
                                color: "#223369",
                                marginTop: "3.7px",
                              }}
                            >
                              Make this as a Gift
                            </h6>
                          </Col>
                        </Row>
                        <FormDetailsPage formData={formData} onClick={handleEditClick}/>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CardPay;
