/* eslint-disable import/no-anonymous-default-export */
const ACCOUNT_FOUND = "account_found";
const LOG_OUT = "log_out";
const LOADER_VISIBLE = "loader_visible";
const SETTINGS_FOUND = "settings_found";
const SETTINGS_FOUND_PLATFORM = "settings_found_platform";
const EMAIL_SUBSCRIBE = "subscribed_to_fyndr_email";

const REFFERAL_WALLET = "refferal_wallet";
const REFFERAL_CODE = "refferal_code";
const GET_EVENTS_BIZ = "get_events_biz";
const UPDATE_QR_LOGO = "update_qr_logo";
const UPDATE_MAIN_LOGO = "update_main_logo";
const LOCATION_EDITED = "Location_edited";
const LOCATION_SELECTED = "Location_selected";
const GET_EVENTS = "get_events";
const FILTER_EVENTS = "filter_events";
const TERMS_NOT_FOUND = "terms_not_found";
const SET_TERMS_LIST = "set_term_list";
const TERMS_FOUND = "terms_found";
const TERMS_INIT = "terms_init";
const TERM_EDITED = "terms_edited";
const UPDT_CMPN = "updt_cmpn";
const UPDT_CMPN_IMG = "updt_cmpn_img";
const UPDT_CMPN_VIDEO="updt_cmpn_video";
const DEL_CMPN_VIDEO="del_cmpn_video"
const UPDT_CMPN_BROCHURE = "updt_cmpn_brochure";
const UPDT_OFFER = "updt_offer";
const UPSERT_OFFER = "upsert_offer";
const DELETE_OFFER = "delete_offer";
const SELECT_OFFER = "select_offer";
const REMOVE_TYPE_SPECIFIC_DATA = "remove_type_specific_data";
const CMPN_UPSERTED = "cmpn_upserted";
const CMPN_SELECTED = "cmpn_selected";
const CMPN_INIT = "cmpn_init";
const CMPNS_FETCHED = "cmpns_fetched";
const CMPN_VIEW = "cmpn_view";
const CMPN_LIKED = "cmpn_liked";
const FETCHED_INDV_LIKES = "fetched_indv_likes";
const FETCHED_BIZ_INFO = "fetched_biz_info";
const CMPN_SEARCH = "cmpn_search";
const UPDT_CMPN_PARAMS_FROM_INTERACTION = "updt_cmpn_search_params";
const ACCOUNT_EDITED = "account_Edit";
const CARDS_LISTED = "cards_listed";
const OFFERS_SELECTED = "offer_Selected";
const RECEIVABLE_UPSERTED = "RECEIVABLE_UPSERTED";
const RECEIVABLES_FETCHED = "RECEIVABLES_FETCHED";
const PAYABLE_UPSERTED = "PAYABLE_UPSERTED";
const PAYABLES_FETCHED = "PAYABLES_FETCHED";
const INVOICE_PAID = "INVOICE_PAID";
const VOUCHER_UPDATED = "VOUCHER_UPDATED";
const PAYABLES_SEARCHED = "PAYABLES_SEARCHED";
const RECEIVABLES_SEARCHED = "RECEIVABLES_SEARCHED";
const UPDT_ONBOARDED = "UPDT_ONBOARDED";
const CONTINUE_ON_WEB = "Continue_On_Web";
const SET_ROUTE = "set_route";
const TOGGLE_OFFCANVAS_NAV = "TOGGLE_OFFCANVAS_NAV";
const CHANGE_SIDENAV_WIDTH = "CHANGE_SIDENAV_WIDTH";
const TOGGLE_OFFCANVAS_MOBILE_NAV = "TOGGLE_OFFCANVAS_MOBILE_NAV";
const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSED_NAV";
const TOGGLE_CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const CHECKOUT_STARTED = "CHECKOUT_STARTED";
const PREVIEW_ITEM_UPDATE = "Preview_Item_Update";
const SET_PENDING_NOTIS = "SET_PENDING_NOTIS";
const SET_IS_BOTTOM = 'SET_IS_BOTTOM';
//Catalogue
const GET_CATALOGUE = "get_catelogue";
const GET_CATEGORIES = "get_categories";
const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
const UPDATE_ITEM = "UPDATE_ITEM";
const UPDATE_MODIFIER = "Update_Modifier";
const UPDATE_CATALOGUE_ITEM = "UPDATE_CATELOG";
const UPDATE_CATALOGUE_MODIFIER = "UPDATE_COLLECTION_MODIFIER";
const UPDATE_CATALOGUE = "UPDATE_COLLECTION";
const SELECTED_COLLECTION = "Selected_Collections";
const FETCH_CATEGORIES = "FETCH_CATEGORIES";
const FETCH_ITEMS = "FETCH_ITEMS";
const FETCH_MODIFIER = "FETCH_MODIFIER";
const FETCH_CATALOGUE = "FETCH_CATALOGUE";
const FETCH_CATALOGUE_ITEMS = "FETCH_CATALOGUE_ITEMS";
const UPDATE_CATALOGUE_LOCATION = "UPDATE_CATALOGUE_LOCATION";
const CART_UPDATED = "CART_UPDATED";
const CATEGORIES_FOUND = "categories_found";
const CART_DELETED = "cart_deleted";
const CART_INVOICED = "CART_INVOICED";
const CART_LOADED = "CART_LOADED";
const CART_STARTED = "CART_STARTED";
const END_DATE = "END_DATE";
const ROLE_TYPE = "ROLE_TYPE";

const LOCOFFER = "LOC_Offer";
const LOCQR = "LOC_QR";
const ISACTIVE = "isActive";

///Role Name

const ROLE_FYNDR = "FYNDR";
const ROLE_MANAGER = "FYNDR_MANAGER";
const ROLE_SUPPORT = "FYNDR_SUPPORT";
const NAV_TAB = "ADMIN";
const SUPER_ADMIN = "SUPER_ADMIN";
const FYNDR_MANAGER = "FYNDR_MANAGER";
const FYNDR_SUPPORT = "FYNDR_SUPPORT";
//Admin
const ADMIN_ACCOUNT = "ADMIN_ACCOUNT";
const MASQUERADE = "MASQUERADE";
const ADMIN_BACK = "ADMIN_BACK";
const STRIPE_DETAILS_UPDATE = "STRIPE_Details_Update";
const UPDATE_BIZ_DETAILS = "UPDATE_BIZ_DETAILS";
const DELETE_DATA = "DELETE_DATA";

export default {
  ISACTIVE,
  RECEIVABLE_UPSERTED,
  RECEIVABLES_FETCHED,
  PAYABLE_UPSERTED,
  PAYABLES_FETCHED,
  INVOICE_PAID,
  VOUCHER_UPDATED,
  PAYABLES_SEARCHED,
  RECEIVABLES_SEARCHED,
  ACCOUNT_FOUND,
  LOG_OUT,
  LOADER_VISIBLE,
  SETTINGS_FOUND,
  SETTINGS_FOUND_PLATFORM,
  GET_EVENTS_BIZ,
  UPDATE_QR_LOGO,
  UPDATE_MAIN_LOGO,
  LOCATION_EDITED,
  LOCATION_SELECTED,
  GET_EVENTS,
  FILTER_EVENTS,
  TERMS_NOT_FOUND,
  SET_TERMS_LIST,
  TERMS_FOUND,
  TERMS_INIT,
  TERM_EDITED,
  UPDT_CMPN,
  UPDT_CMPN_IMG,
  UPDT_CMPN_BROCHURE,
  UPDT_OFFER,
  UPSERT_OFFER,
  DELETE_OFFER,
  SELECT_OFFER,
  REMOVE_TYPE_SPECIFIC_DATA,
  CMPN_UPSERTED,
  CMPN_SELECTED,
  CMPN_INIT,
  CMPNS_FETCHED,
  CMPN_VIEW,
  CMPN_LIKED,
  FETCHED_INDV_LIKES,
  FETCHED_BIZ_INFO,
  CMPN_SEARCH,
  UPDT_CMPN_PARAMS_FROM_INTERACTION,
  ACCOUNT_EDITED,
  CARDS_LISTED,
  UPDT_ONBOARDED,
  OFFERS_SELECTED,
  CONTINUE_ON_WEB,
  SET_ROUTE,
  TOGGLE_OFFCANVAS_NAV,
  CHANGE_SIDENAV_WIDTH,
  TOGGLE_OFFCANVAS_MOBILE_NAV,
  TOGGLE_COLLAPSED_NAV,
  LOGIN_SUCCESS,
  CHECKOUT_STARTED,
  PREVIEW_ITEM_UPDATE,
  SET_PENDING_NOTIS,
  GET_CATALOGUE,
  GET_CATEGORIES,
  UPDATE_CATEGORIES,
  UPDATE_ITEM,
  UPDATE_MODIFIER,
  UPDATE_CATALOGUE_ITEM,
  UPDATE_CATALOGUE,
  SELECTED_COLLECTION,
  UPDATE_CATALOGUE_MODIFIER,
  FETCH_CATEGORIES,
  FETCH_ITEMS,
  FETCH_MODIFIER,
  FETCH_CATALOGUE,
  FETCH_CATALOGUE_ITEMS,
  UPDATE_CATALOGUE_LOCATION,
  CART_UPDATED,
  CATEGORIES_FOUND,
  CART_DELETED,
  CART_INVOICED,
  CART_STARTED,
  CART_LOADED,
  LOCOFFER,
  LOCQR,
  TOGGLE_CREATE_CAMPAIGN,
  ROLE_FYNDR,
  ADMIN_ACCOUNT,
  MASQUERADE,
  ADMIN_BACK,
  NAV_TAB,
  STRIPE_DETAILS_UPDATE,
  UPDATE_BIZ_DETAILS,
  DELETE_DATA,
  REFFERAL_WALLET,
  REFFERAL_CODE,
  END_DATE,
  ROLE_MANAGER,
  ROLE_SUPPORT,
  ROLE_TYPE,
  SUPER_ADMIN,
  FYNDR_MANAGER,
  FYNDR_SUPPORT,
  UPDT_CMPN_VIDEO,
  DEL_CMPN_VIDEO,
  SET_IS_BOTTOM,
  EMAIL_SUBSCRIBE
};
